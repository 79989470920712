import { NextRouter } from 'next/router'
import { config, RouteName } from './config'
import { entries } from './typeHelpers'

const getQueryParams = <ParamType>(
  router: NextRouter,
  params: string[],
  formatValue: (raw: string) => ParamType,
): Record<string, ParamType | undefined> =>
  params.reduce((acc, param) => {
    const queryParam = router.query[param]
    return {
      ...acc,
      [param]: typeof queryParam === 'string' ? formatValue(queryParam) : undefined,
    }
  }, {})

export const isUnprotectedRoute = (pathname: string): boolean => {
  const pathToNameMap = entries(config.routes).reduce(
    (acc, [name, path]) => ({ ...acc, [path]: name }),
    {} as Record<string, RouteName>,
  )
  return config.unprotectedRoutes.includes(pathToNameMap[pathname] || '')
}

export const getUrlParameters = (router: NextRouter, ...params: string[]) =>
  getQueryParams(router, params, (raw) => raw)

export const getIntegerParameters = (router: NextRouter, ...params: string[]) =>
  getQueryParams(router, params, (raw) => {
    const value = parseInt(raw, 10)
    return Number.isNaN(value) ? undefined : value
  })

export const getBaseRoutePart = (route: string) => route.split('/')[1] || ''
