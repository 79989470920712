// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"f2e9e01dae18491b3127ad3402975e56bbe827b9"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import { isProductionEnv } from '@netpurpose/utils'
import { sentryConfig } from './sentryShared.config'

Sentry.init({
  ...sentryConfig,
  replaysSessionSampleRate: isProductionEnv() ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [Sentry.replayIntegration()],
})
